<template>
  <transition appear enter-active-class="animated fadeIn">
    <div>
      <div class="level-item">
        <h1 class="title is-1 page-title">GROUP TRAVEL INSURANCE</h1>
      </div>
      <div class="box b-shdw-3">
				<InfoButtonOptions :policyType="'ST'" :coverType="'ST'"/>

				<div class="content">
					<h2>Group Travel Insurance</h2>
					<p class="mb-4">
						If you are holidaying with a group of friends, then it may worth seeing if group travel insurance is better, rather than purchasing individual policies. Our questions and answers below will provide you with more information about group travel insurance, to help you decide on which type of policy is right for you. 
					</p>

					<h2>What is group travel insurance?</h2>
					<p class="mb-4">
						Our group travel insurance will cover up to 14 adults who are travelling together. For group travel, having one policy in place may be the ideal choice, to simplify the paperwork.
						<br><br>
						However, if you or anyone else in your travelling party is planning on going away on multiple holidays in the year separately, then group travel insurance may not be the right choice. In this circumstance, an annual multi-trip policy might be the better choice.  
					</p>

					<h2>Why choose group travel insurance?</h2>
					<p class="mb-4">
						Group travel insurance can be a simple solution as there is only one set of insurance, meaning less paperwork. You only purchase one policy, rather than multiple policies for your group. 
						<br><br>
						Group cover can be an option to all Covered2go travel insurance policies, and as such, you can also benefit from the following:
					</p>
					<ul class="mb-4">
						<li>Covid-19 cover* (which includes up to £10,000 for cancellation / curtailment due to contracting Covid-19). </li>
						<li>Up to £10M medical expenses and repatriation (include cover if you contracted Covid-19 abroad)</li>
						<li>Cover for your personal possessions, including mobile phones when you choose our highest tier of cover</li>
						<li>24-hour emergency medical assistance</li>
					</ul>

					<h2>Why group travel insurance might not be the right choice.</h2>
					<p class="mb-4">
						In certain circumstances, group travel insurance may not be the right choice for you, for example:
					</p>
					<ul class="mb-4">
						<li>If you are significantly older than everyone else, as this can increase the costs for the other individuals in your travelling party. </li>
						<li>If you are going on other trips in the same year, separately from the group, it might be more economical overall to get an annual multi-trip policy. </li>
					</ul>

					<h2>Do you have to be related to get a group travel insurance policy?</h2>
					<p class="mb-4">
						No, group travel insurance is for any group of adults and you do not need to be related, you just need to be travelling together. 
					</p>

					<h2>How many people can be insured on a group policy?</h2>
					<p class="mb-4">
						Our policies currently cover up to 14 adults on a group policy, however, if you are looking to insure more than 14, we would be more than happy to discuss your requirements with you,
						<router-link :to="{name:'contactUs'}"> contact us </router-link>
						today for a group travel insurance quote. 
					</p>

					<p class="mb-4">
						<router-link :to="{name:'getQuote'}">Get a quote for group travel insurance today.</router-link>
					</p>
					
				</div>
				
				<InfoButtonOptions :policyType="'ST'" :coverType="'ST'"/>
				<info-boxes/>
				<!-- <trustpilot-banner/> -->

			</div>
    </div>
  </transition>
</template>

<script>
import InfoBoxes from "../components/common/InfoBoxes";
//import TrustpilotBanner from "@/components/common/trustpilot/TrustpilotBanner";
import InfoButtonOptions from "@/components/common/InfoButtonOptions";
		
export default {
  name: 'GroupTravelInsurance',
	data() {
		return {
			countriesCovered: null
		}
	},
  mixins: [],
  components: {
			InfoBoxes,
			//TrustpilotBanner,
			InfoButtonOptions
	}
};
</script>

<style lang="scss" scoped>
	@import 'src/styles/views/info-page';
</style>
